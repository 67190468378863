import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import Wix from '../../services/wix-sdk-polyfill';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import Link from '../link/internal-link';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import { isSeo, isSSR } from '../../store/basic-params/basic-params-selectors';
import { connect } from '../runtime-context';

import styles from './more-categories-dropdown.scss';

class MoreCategoriesDropdown extends Component {
  state = {
    showItems: false,
    moreButtonHover: false,
  };

  getHeaderAndDropdownHeight = () => {
    const menuHeight = document.querySelector('[data-hook="blog-desktop-header-container"]').offsetHeight;
    const dropdownElementHeight = document.querySelector(
      '[data-hook="blog-desktop-header-dropdown-menu-container"]',
    ).offsetHeight;
    return dropdownElementHeight + menuHeight;
  };

  setHeightIfNeeded = () => {
    const headerAndDropdownHeight = this.getHeaderAndDropdownHeight();
    const documentHeight = document.body.offsetHeight;
    if (headerAndDropdownHeight > documentHeight) {
      // This can be removed once OOI in preview is fully opened.
      Wix.setHeight(headerAndDropdownHeight);
    }
  };

  handleShowItems = () => {
    this.setState({ showItems: true, moreButtonHover: true });
  };

  handleHideItems = () => {
    this.setState({ showItems: false, moreButtonHover: false });
  };

  handleHideItemsOnBlur = (event) => {
    if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
      this.handleHideItems();
    }
  };

  isActive = (item) => {
    const { currentPath } = this.props;
    return item.path === currentPath;
  };

  componentDidUpdate() {
    if (this.state.showItems === true) {
      this.setHeightIfNeeded();
    }
  }

  renderItems() {
    const { items, hoverClassName, activeClassName, className, width } = this.props;

    return (
      <div className={styles.container} data-hook="blog-desktop-header-dropdown-menu-container">
        <div
          className={classNames(styles.dropdownContainer, 'blog-categories-dropdown-background-color')}
          style={{ maxWidth: width }}
        >
          {items.map((item) => {
            return (
              <div className={classNames(styles.dropdownItem)} key={item.key}>
                <div className={classNames(styles.text, 'blog-navigation-container-color')}>
                  <Link
                    addHoverClasses={false}
                    to={item.path}
                    className={classNames(className, hoverClassName, {
                      [activeClassName]: this.isActive(item),
                    })}
                    navigateWithoutScroll={!this.props.isSSR && !this.props.isSEO}
                    isActive={this.isActive(item)}
                    onNavigation={item.onNavigation}
                  >
                    {item.text}
                  </Link>
                </div>
                <div className={classNames(styles.separator, 'blog-navigation-container-separator-background-color')} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { moreText, activeClassName, className, applyFeedDesign } = this.props;
    const { showItems, moreButtonHover } = this.state;

    return (
      <div
        onMouseLeave={this.handleHideItems}
        style={{ lineHeight: 1.5 }}
        className={classNames(className, { [activeClassName]: moreButtonHover })}
        onBlur={this.handleHideItemsOnBlur}
      >
        <span
          onMouseEnter={this.handleShowItems}
          onFocus={this.handleShowItems}
          className={styles.dropdownLink}
          tabindex="0"
        >
          <span>{moreText}</span>
          {applyFeedDesign ? (
            <span style={{ position: 'relative', width: '1em', marginLeft: '0.2em' }}>
              <ArrowDownIcon style={{ width: '1em', position: 'absolute', bottom: '0.2em' }} />
            </span>
          ) : (
            <ArrowDownIcon height="12" style={{ float: 'right', marginTop: 4 }} />
          )}
        </span>
        {showItems && this.renderItems()}
      </div>
    );
  }
}

MoreCategoriesDropdown.propTypes = {
  currentPath: PropTypes.string,
  items: PropTypes.array.isRequired,
  moreText: PropTypes.string.isRequired,
  hoverClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  applyFeedDesign: PropTypes.bool.isRequired,
  isSSR: PropTypes.bool.isRequired,
  isSEO: PropTypes.bool.isRequired,
  totalItems: PropTypes.number.isRequired,
};

const mapRuntimeToProps = (state) => ({
  isSSR: isSSR(state),
  isSEO: isSeo(state),
});

export default flowRight(withIsFeedDesignEnabled, connect(mapRuntimeToProps))(MoreCategoriesDropdown);
